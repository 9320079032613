




































































import Vue, { PropType } from "vue";
import { Connection } from "@/store/connections/types";
import { ConnectionsBody } from "@/api/connections";
import { pagingDefault, Paging } from "@/store/utils/";
import { namespace } from "vuex-class";
import { Shop } from "@/store/shops/types";
import { Event } from "@sentry/types";

const connectionsModule = namespace("connections");
const shopsModule = namespace("shops");

export default Vue.extend({
  name: "MyCustomer",

  computed: {
    _pagination: {
      get: function () {
        return this.$store.state.connections.pagination;
      },
      set: function (value: Paging) {
        this.$store.dispatch("connections/paginate", value);
      },
    },
    loading: function () {
      return this.$store.state.connections.loading;
    },
    myCustomers: function () {
      return this.$store.getters["connections/myCustomers"];
    },
  },

  data: function () {
    return {
      deleteConfirm: false,
      searchVal: "",
      showDisabled: false,
      code: "",
      add: false,

      headers: [
        { text: "Connection Id", value: "connectionId" },
        { text: "Customer Code", value: "customerCode" },
        { text: "Customer Name", value: "customerName" },
        { text: "Types", value: "types" },
        { text: "Contact Name", value: "contactName" },
        { text: "Province", value: "province" },
        { text: "City", value: "city" },
        { text: "Active", value: "action" },
      ],

      // This is temp solution until we can filter by organisation on api side
      paginationTemp: { ...pagingDefault },
    };
  },

  created: function () {
    this.$store.dispatch("connections/fetchConnections");
  },

  mounted() {
    this.$store.dispatch("connections/fetchConnectionByOrgCode", {
      code: "",
    });
    this._pagination = { ...pagingDefault, itemsPerPage: -1 };
  },

  methods: {
    onNextPage(newx: any, oldx: any) {
      this.$store.dispatch("connections/fetchConnections");
    },
    onShopChange(newx: any, oldx: any) {
      this.$store.dispatch("connections/fetchConnections");
    },
    addCustomer() {
      this.$router.push({ name: "possible-connections" });
    },
    async onSearchCustomer() {
      let results = await this.$store.dispatch(
        "connections/fetchConnectionByOrgCode",
        { code: this.code }
      );
    },
    onRowClick(connectionId: number) {
      this.$store.dispatch(
        "connections/fetchConnectionByConnectionId",
        connectionId
      );

      this.$router.push({
        name: "my-customer-overview",
        params: {
          connectionId: connectionId.toString(),
        },
      });
    },
    onShowDisabled(showDisabled: boolean) {
      this.$store.dispatch("connections/fetchConnections", {
        showDisabled: showDisabled,
      });
    },
    deleteConnections(id: number) {
      this.$store.dispatch("connections/deleteConnections", id);
    },
    enableConnection(id: number) {
      this.$store.dispatch("connections/enableConnection", id);
    },
    viewCustomerDiscount(id: string) {
      this.$router.push({
        name: "my-customers-discount",
        params: { connectionId: id },
      });
    },
    orderFor(connectionId: number) {
      this.$router.push({
        name: "customer-order",
        params: {
          connectionId: connectionId.toString(),
        },
      });
    },
    toUpper(e: any) {
      this.code = e.target.value.toUpperCase();
    },
    exportToCSV() {
      // encapsulate `types` as it can contain ,
      const arrData = this.$store.getters["connections/myCustomers"].map(
        (item: any) => ({
          ...item,
          types: `"${item.types}"`,
        })
      );
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(","),
        ...arrData.map((item: any) => Object.values(item).join(",")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);

      const date = new Date();

      link.setAttribute(
        "download",
        `My_Customers_${date.toISOString().split("T")[0]}.csv`
      );
      link.click();
    },
  },
});
